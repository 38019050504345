exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-disclosure-index-tsx": () => import("./../../../src/pages/disclosure/index.tsx" /* webpackChunkName: "component---src-pages-disclosure-index-tsx" */),
  "component---src-pages-gold-trading-account-index-tsx": () => import("./../../../src/pages/gold-trading-account/index.tsx" /* webpackChunkName: "component---src-pages-gold-trading-account-index-tsx" */),
  "component---src-pages-home-index-tsx": () => import("./../../../src/pages/home/index.tsx" /* webpackChunkName: "component---src-pages-home-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-interest-policy-index-tsx": () => import("./../../../src/pages/interest-policy/index.tsx" /* webpackChunkName: "component---src-pages-interest-policy-index-tsx" */),
  "component---src-pages-kyc-index-tsx": () => import("./../../../src/pages/kyc/index.tsx" /* webpackChunkName: "component---src-pages-kyc-index-tsx" */),
  "component---src-pages-markets-index-tsx": () => import("./../../../src/pages/markets/index.tsx" /* webpackChunkName: "component---src-pages-markets-index-tsx" */),
  "component---src-pages-ordering-policies-index-tsx": () => import("./../../../src/pages/ordering-policies/index.tsx" /* webpackChunkName: "component---src-pages-ordering-policies-index-tsx" */),
  "component---src-pages-platinum-trading-account-index-tsx": () => import("./../../../src/pages/platinum-trading-account/index.tsx" /* webpackChunkName: "component---src-pages-platinum-trading-account-index-tsx" */),
  "component---src-pages-privacy-index-tsx": () => import("./../../../src/pages/privacy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-index-tsx" */),
  "component---src-pages-risk-disclosure-policy-index-tsx": () => import("./../../../src/pages/risk-disclosure-policy/index.tsx" /* webpackChunkName: "component---src-pages-risk-disclosure-policy-index-tsx" */),
  "component---src-pages-silver-trading-account-index-tsx": () => import("./../../../src/pages/silver-trading-account/index.tsx" /* webpackChunkName: "component---src-pages-silver-trading-account-index-tsx" */),
  "component---src-pages-terms-index-tsx": () => import("./../../../src/pages/terms/index.tsx" /* webpackChunkName: "component---src-pages-terms-index-tsx" */),
  "component---src-pages-webtrader-index-tsx": () => import("./../../../src/pages/webtrader/index.tsx" /* webpackChunkName: "component---src-pages-webtrader-index-tsx" */)
}

